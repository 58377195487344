import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { set } from "../../store/slices/auth";
import { setFilters } from "../../store/slices/filters";
import SelectBox from "../../components/Form/SelectBox";
import CheckBox from "../../components/Form/CheckBox";
import Button from "../../components/LinkButton";
import axe from "../../helpers/axe";

const PageTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

const InputTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Form = styled.div`
  max-width: 500px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

export default function GitHubLoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState(null);
  const [form, setForm] = useState({
    country: null,
    is_confirmed: false,
    email_subscription: false,
  });

  const handleCreateAccount = async () => {
    try {
      await axios.put(
        `confirmation`,
        {
          country_id: form.country.id,
          is_confirmed: form.is_confirmed,
          email_subscription: form.email_subscription,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      dispatch(
        setFilters({
          place_id: form.country.id,
        })
      );
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "account_confirmation",
      //     count: 1,
      //     segmentation: {
      //       succeed: true,
      //     },
      //   },
      // ]);
      dispatch(set(data));
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Bir hata meydana geldi!");
      navigate("/");
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "account_confirmation",
      //     count: 1,
      //     segmentation: {
      //       succeed: false,
      //     },
      //   },
      // ]);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const { data } = await axe("places")
        .select(["id", "title"])
        .where("place_type", "=", "COUNTRY")
        .paginate(1, 300);
      setCountries(data);
    };

    const getAuthorizationToken = async () => {
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "login_try",
      //     count: 1,
      //     segmentation: {
      //       succeed: true,
      //     },
      //   },
      // ]);
      try {
        const { data } = await axios.post("login", {
          code: query.get("code"),
        });
        if (!data.user.is_new_user) {
          setForm({
            ...form,
            is_confirmed: true,
          });
        }
        if (data.user.default_country_id) {
          dispatch(set(data));
          dispatch(
            setFilters({
              place_id: data.user.default_country_id,
            })
          );
          navigate("/");
        } else {
          setData(data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.error || "Bir hata meydana geldi!");
        navigate("/");
        // Countly.q.push([
        //   "add_event",
        //   {
        //     key: "login_try",
        //     count: 1,
        //     segmentation: {
        //       succeed: false,
        //     },
        //   },
        // ]);
      }
    };

    getAuthorizationToken();
    fetchCountries();
    // eslint-disable-next-line
  }, []);

  if (data === null) {
    return (
      <>
        <Form style={{ textAlign: "center", padding: "100px 30px" }}>
          <SyncLoader color="#18181b" loading={true} css={{}} size={15} />
        </Form>
      </>
    );
  }

  return (
    <>
      <Form>
        <PageTitle>Profil ayarları</PageTitle>
        <InputTitle>Ülke *</InputTitle>
        <SelectBox
          value={form.country}
          onChange={(country) => setForm({ ...form, country })}
          options={countries}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          isSearchable={true}
          isClearable={true}
          placeholder="Ülke seç"
        />
        {data.user.is_new_user && (
          <div style={{ marginTop: "10px" }}>
            <CheckBox
              label="Gizlilik ve Kullanım Şartları'nı kabul ediyorum. *"
              value={form.is_confirmed}
              onChange={(event) =>
                setForm({ ...form, is_confirmed: event.target.checked })
              }
            />
          </div>
        )}
        {/* <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <CheckBox
            label="Güncellemeler için e-posta aboneliği oluşturç"
            value={form.email_subscription}
            defaultValue={form.email_subscription}
            onChange={(event) =>
              setForm({ ...form, email_subscription: event.target.checked })
            }
          />
        </div> */}
        <div style={{ marginTop: "10px" }}>
          <Button
            type="button"
            block={true}
            onClick={handleCreateAccount}
            disabled={!form.country || !form.is_confirmed}
          >
            {data.user.is_new_user && "Kaydet"}
            {!data.user.is_new_user && "Güncelle"}
          </Button>
        </div>
      </Form>
    </>
  );
}
